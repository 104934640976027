import React, { useState } from 'react';
import useUserData from '../hooks/useUserData';
import HeaderUI from '../components/HeaderUI';
import '../styles/TerminalStyle.css';
import '../styles/ConnectUI.css';

const ConnectUI = () => {
    const { user, loading, error } = useUserData();
    const [ipHistory, setIpHistory] = useState([]);
    const [connectionManager, setConnectionManager] = useState([]);
    const [newIp, setNewIp] = useState('');

    // Add IP to history manager
    const addIpToHistory = () => {
        if (newIp.trim() && !ipHistory.includes(newIp)) {
            setIpHistory([...ipHistory, newIp]);
            setNewIp(''); // Clear input field
        }
    };

    // Add IP to connection manager
    const addIpToConnectionManager = (ip) => {
        if (!connectionManager.includes(ip)) {
            setConnectionManager([...connectionManager, ip]);
        }
    };

    // Remove IP from history manager
    const removeIpFromHistory = (ip) => {
        setIpHistory(ipHistory.filter((historyIp) => historyIp !== ip));
    };

    // Remove IP from connection manager
    const removeIpFromConnectionManager = (ip) => {
        setConnectionManager(connectionManager.filter((connIp) => connIp !== ip));
    };

    // Connect logic
    const handleConnect = () => {
        if (connectionManager.length === 0) {
            alert('No IPs selected for connection.');
        } else if (connectionManager.length === 1) {
            alert(`Directly connecting to ${connectionManager[0]}...`);
        } else {
            alert(
                `Bounce connection through ${connectionManager.slice(0, -1).join(' → ')} → ${connectionManager[connectionManager.length - 1]}...`
            );
        }
    };

    if (loading) {
        return <p>Loading user data...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="terminal-ui">
            <HeaderUI user={user} />
            <hr />

            <div className="terminal-content-wrapper">
                {/* IP History Manager */}
                <div className="terminal-file-list">
                    <h3>IP History Manager</h3>
                    <div className="ip-history">
                        {ipHistory.map((ip) => (
                            <div key={ip} className="ip-item">
                                {ip}
                                <button onClick={() => addIpToConnectionManager(ip)}>Add to Connection</button>
                                <button onClick={() => removeIpFromHistory(ip)}>Remove</button>
                            </div>
                        ))}
                    </div>
                    <div className="ip-add-form">
                        <input
                            type="text"
                            placeholder="Enter new IP"
                            value={newIp}
                            onChange={(e) => setNewIp(e.target.value)}
                        />
                        <button onClick={addIpToHistory}>Add IP</button>
                    </div>
                </div>

                {/* Connection Manager */}
                <div className="terminal-connection-manager">
                    <h3>Connection Manager</h3>
                    <div className="connection-list">
                        {connectionManager.map((ip) => (
                            <div key={ip} className="connection-item">
                                {ip}
                                <button onClick={() => removeIpFromConnectionManager(ip)}>Remove</button>
                            </div>
                        ))}
                    </div>
                    <button onClick={handleConnect}>Connect</button>
                </div>
            </div>

            <hr />

            <div className="terminal-footer">
                <span>
                    <button>💬 Chat</button>
                    System: Welcome to HLC MMO!
                </span>
            </div>
        </div>
    );
};

export default ConnectUI;
