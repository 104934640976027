// src/utils/validateForm.js
export const validateForm = (formData, isNewUser, setMessage) => {
    // Destructure form data and trim values
    const { username, password, confirmPassword, email } = formData;
    const trimmedUsername = username?.trim();
    const trimmedEmail = email?.trim();

    // Username validation
    if (!trimmedUsername || trimmedUsername.length < 3) {
        setMessage('Username must be at least 3 characters long');
        return false;
    }
    if (trimmedUsername.length > 20) {
        setMessage('Username must be between 3 and 20 characters long');
        return false;
    }
    if (!/^[a-zA-Z0-9_]+$/.test(trimmedUsername)) {
        setMessage('Username can only contain letters, numbers, and underscores');
        return false;
    }

    // Password validation
    if (!password || password.length < 8) {
        setMessage('Password must be at least 8 characters long');
        return false;
    }
    if (!/(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])/.test(password)) {
        setMessage('Password must include uppercase, lowercase, number, and special character');
        return false;
    }

    // Password confirmation validation (for new users)
    if (isNewUser && password !== confirmPassword) {
        setMessage('Passwords do not match');
        return false;
    }

    // Email validation (for new users)
    if (isNewUser && (!trimmedEmail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedEmail))) {
        setMessage('Email format is invalid');
        return false;
    }

    // All validations passed
    return true;
};
