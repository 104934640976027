import React, { useState, useEffect } from 'react';

const BootUI = ({ onBootComplete }) => {
    const [messages, setMessages] = useState([]);
    const [booting, setBooting] = useState(true);

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        const bootSequence = async () => {
            const bootSteps = [
                { message: "Booting HLC v0.1...", delay: 200 },
                { message: "*", delay: 200 },
                { message: "*", delay: 200 },
                { message: "JMP 0x1F", delay: 300 },
                { message: "POPL %ESI", delay: 300 },
                { message: "MOVL %ESI 0x8(%ESI)", delay: 300 },
                { message: "XORL %EAX, %EAX", delay: 300 },
                { message: "Establishing secure connection..", delay: 500 },
                { message: ".", delay: 300 },
                { message: ".", delay: 300 },
                { message: "Connecting to HLC Gateway", delay: 300 },
                { message: ".", delay: 300 },
                { message: ".", delay: 300 },
                { message: ".", delay: 300 },
                { message: "Connection Established!", delay: 1000 },
            ];

            const newMessages = [];

            for (const step of bootSteps) {
                newMessages.push(step.message);
                setMessages([...newMessages]);
                await delay(step.delay);
            }

            setBooting(false);
            onBootComplete();
        };

        bootSequence();
    }, [onBootComplete]);

    return (
        <div className="boot-ui">
            {booting && messages.map((msg, index) => (
                <div key={index}>{msg}</div>
            ))}
        </div>
    );
};

export default BootUI;
