import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//import '../styles/HeaderUI.css';
import '../styles/TerminalStyle.css';

const HeaderUI = ({ user = {} }) => {
    const [currentTime, setCurrentTime] = useState('');
    const navigate = useNavigate();
    const [showIP, setShowIP] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    
    // Handle date time
    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            const timeString = now.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            });
            const dateString = now.toLocaleDateString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
            setCurrentTime(`${timeString} on ${dateString}`);
        };

        const intervalId = setInterval(updateTime, 1000);
        updateTime();

        return () => clearInterval(intervalId);
    }, []);
    
    // Logout function
    const handleLogout = () => {
        // Clear the authentication token (or any other user data) from storage
        localStorage.removeItem('token'); // or sessionStorage.removeItem('token') if needed

        // Redirect to login page
        navigate('/login');
    };
    
    // goto function
    const gotoDesktop = () => { navigate('/desktop') };
    const gotoConnect = () => { navigate('/connect') };
    
    //UI
    return (
        <div>
            
            <div className="terminal-header">
                <span>It is {currentTime}</span>
                <button className="menu-button" onClick={toggleMenu}>☰ Menu</button>
                {menuOpen && (
                    <div className="menu-dropdown">
                        <button>⚙ Settings</button>
                        <button onClick={handleLogout}>🚪 Logout</button>
                    </div>
                )}
            </div>

            <div className="user-info">
                User: {user.username || 'N/A'} |
                Rep: {user.reputation || 'N/A'} |
                IP: {user.ipaddress || 'N/A'} |
                HM: {user.hackmoney || 'N/A'} |
                BM: {user.blackmoney || 'N/A'}
            </div>

            <hr />

            <div className="notification-bar">
                🔔 Notification: 0 new notification.
            </div>

            <div className="terminal-menu-bar">
                <button onClick={gotoDesktop}>🖳 Desktop</button>
                <button>📜 Tasks</button>
                <button onClick={gotoConnect}>🌐 Connect</button>
                <button>✉️ Email</button>
                <button>🖥 Hardware</button>
                <button>📦 Software</button>
                <button>🛒 Shop</button>
                <button>🏆 Leaderboard</button>
                <button>🛡 Guild</button>
                <button>💬 Chat</button>
            </div>
        </div>
    );
};

export default HeaderUI;