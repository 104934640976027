import React, { useState, useEffect } from 'react';
//import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './styles/global.css';
import { checkServerStatus } from './services/statusService';
import BootUI from './pages/BootUI';
import LoginUI from './pages/LoginUI';
import MaintenanceUI from './pages/MaintenanceUI';
import DesktopUI from './pages/DesktopUI';
import ConnectUI from './pages/ConnectUI';

function App() {
    const routes = [
        { path: '/', element: <Navigate to="/login" /> },
        { path: '/login', element: <LoginUI /> },
        { path: '/desktop', element: <DesktopUI /> },
        { path: '/connect', element: <ConnectUI /> },
        // Add more routes as needed
    ];

    const [isBootComplete, setIsBootComplete] = useState(false);
    const [isMaintenance, setIsMaintenance] = useState(false);

    useEffect(() => {
        const verifyServerStatus = async () => {
            const serverUp = await checkServerStatus();
            setIsMaintenance(!serverUp);
        };
        verifyServerStatus();
    }, []);

    const handleBootComplete = () => setIsBootComplete(true);

    if (isMaintenance) {
        return <MaintenanceUI />;
    }

    return (
        <Router>
            <div className="App">
                {!isBootComplete ? (
                    <BootUI onBootComplete={handleBootComplete} />
                ) : (
                    <Routes>
                        {routes.map((route, index) => (
                            <Route key={index} path={route.path} element={route.element} />
                        ))}
                    </Routes>
                )}
            </div>
        </Router>
    );
}

export default App;
