// src/pages/LoginUI.js
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from '../utils/formatDateTime';
import { validateForm } from '../utils/validateForm';
import LoadingSpinner from '../components/LoadingSpinner';
import '../styles/LoginUI.css';

const LoginUI = () => {
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState('');
    const [formData, setFormData] = useState({ username: '', password: '', email: '', confirmPassword: '' });
    const [isNewUser, setIsNewUser] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => setCurrentTime(formatDateTime()), 1000);
        return () => clearInterval(intervalId);
    }, []);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }, []);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setMessage('');

        if (!validateForm(formData, isNewUser, setMessage)) {
            setIsSubmitting(false);
            return;
        }

        try {
            const endpoint = isNewUser ? '/api/register' : '/api/login';
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: formData.username,
                    password: formData.password,
                    ...(isNewUser && { email: formData.email })
                }),
            });

            if (!response.ok) {
                const errorResponse = await response.json();
                setMessage(errorResponse.message || 'An error occurred. Please try again.');
                return;
            }

            const result = await response.json();
            setMessage(`Successfully ${isNewUser ? 'registered' : 'logged in'}!`);
            localStorage.setItem('token', result.token);
            navigate('/desktop');
        } catch (error) {
            console.error('Error:', error);
            setMessage('An error occurred. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <p className="login-ui">
                <b>Connected to HLC Gateway port 1337</b><br /><br />
                It is {currentTime || ".."}<br />
                Harness Power Unlock Mankind
            </p>

            <form onSubmit={handleFormSubmit} style={{ textAlign: 'left', marginTop: '20px', marginLeft: '0px' }}>
                <input
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Username"
                    value={formData.username}
                    onChange={handleInputChange}
                    required
                    aria-required="true"
                    className="input-field"
                />
                <br />
                <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                    aria-required="true"
                    className="input-field"
                />
                <button type="button" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? 'Hide' : 'Show'}
                </button>
                <br />

                {isNewUser && (
                    <>
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirmPassword"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                            required
                            aria-required="true"
                            className="input-field"
                        />
                        <button type="button" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            {showConfirmPassword ? 'Hide' : 'Show'}
                        </button>
                        <br />
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                            aria-required="true"
                            className="input-field"
                        />
                        <br />
                    </>
                )}

                <button
                    type="submit"
                    className="login-button"
                    aria-label={isNewUser ? 'Register' : 'Log in'}
                    disabled={isSubmitting}
                    style={{ marginLeft: '60px' }}
                >
                    {isSubmitting ? <LoadingSpinner /> : (isNewUser ? 'Register' : 'Login')}
                </button>

                <p style={{ marginTop: '0px', fontSize: '15px' }}>
                    {isNewUser ? "Already have an account?" : "Create account? "}
                    <button
                        type="button"
                        className="link-button"
                        onClick={() => setIsNewUser(!isNewUser)}
                        aria-label={isNewUser ? 'Switch to Login' : 'Switch to Register'}
                        style={{ marginLeft: '0px', fontSize: '15px' }}
                    >
                        {isNewUser ? 'Login' : 'Register'}
                    </button>
                </p>

                <p className="status-message" aria-live="assertive">{message}</p>
            </form>
        </div>
    );
};

export default LoginUI;
