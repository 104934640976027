// MaintenancePage.js
import React from 'react';

const MaintenancePage = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Server Under Maintenance</h1>
            <p>We're currently performing maintenance. Please check back later.</p>
        </div>
    );
};

export default MaintenancePage;
