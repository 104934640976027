import React, { useState } from 'react';
import useUserData from '../hooks/useUserData';
import HeaderUI from '../components/HeaderUI';
import '../styles/TerminalStyle.css'; // Import terminal-style CSS

const DesktopUI = () => {
    const { user, loading, error } = useUserData();
    
    if (loading) {
        return <p>Loading user data...</p>; // Optional loading state
    }
    
    if (error) {
        return <p>Error: {error}</p>; // Optional error state
    }

    return (
        <div className="terminal-ui">
            
            <HeaderUI user={user} />
            
            <hr />

            <div className="terminal-content-wrapper">
                <div className="terminal-file-list">
                    <button>🗄 Files</button>
                    <div>/home/pejal/</div>
                    <div>📁 apps</div>
                    <div>📄 message.txt</div>
                    <div>📁 packages</div>
                    <div>📄 notes.txt</div>
                    <div>📁 social</div>
                    <div>📁 system</div>
                    <div>📄 firewall.log</div>
                </div>
            </div>
            
            <hr />
            
            <div className="terminal-footer">
                <span>
                    <button>💬 Chat</button>
                    System: Welcome to HLC MMO!
                </span>
            </div>
        </div>
    );
};

export default DesktopUI;
