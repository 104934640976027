// src/hooks/useUserData.js
import { useState, useEffect } from 'react';

const useUserData = () => {
    const [user, setUser] = useState({
        username: 'Loading...',
        rep: 0,
        ip: 'Loading...',
        hm: 0,
        bm: 0,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('/api/getuserdata', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                
                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }
                
                const data = await response.json();
                setUser(data);
            } catch (err) {
                console.error('Error fetching user data:', err);
                setError('Failed to load user data');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    return { user, loading, error };
};

export default useUserData;
