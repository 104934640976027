// services/statusService.js

export const checkServerStatus = async () => {
    try {
        const response = await fetch('/api/health');
        
        if (!response.ok) {
            throw new Error(`Server returned status ${response.status}`);
        }
        
        return true;
    } catch (error) {
        console.error("Server is down:", error);
        return false;
    }
};
